/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Box, Button, Link, Text } from "theme-ui"

type BioProps = {
  intro?: string
}

const Bio = ({ intro }: BioProps) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          title
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const title = data.site.siteMetadata?.title
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <Box
      sx={{
        display: "flex",
        p: 3,
        backgroundColor: "#f4f1de",
        borderRadius: 10,
        fontSize: [14, 16],
      }}
    >
      <Box sx={{ display: ["none", "block"], mr: [3], width: 75, height: 75 }}>
        <StaticImage
          layout="fixed"
          formats={["auto", "webp", "avif"]}
          src="../images/tom.jpg"
          width={75}
          height={75}
          quality={95}
          alt="Profile picture"
          style={{ borderRadius: 10 }}
        />
      </Box>
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        {intro && <Text sx={{ mb: 2, fontWeight: "bold" }}>{intro}</Text>}
        <Text sx={{ flex: 1 }}>
          My name is Tom and I'm an engineer and technical leader from the UK,
          now based in Christchurch, New Zealand. My interests include platform
          engineering, software architecture and DevOps. Outside of work I love
          playing the drums and am studying for my private pilot's licence.
        </Text>
        <Box sx={{ mt: 3 }}>
          <a href="https://www.getrevue.co/profile/tomhallam">
            <Button
              sx={{
                cursor: "pointer",
                "&:hover": { backgroundColor: "brown" },
                fontSize: [13, "inherit"],
              }}
              style={{ cursor: "pointer" }}
            >
              Subscribe for Updates
            </Button>
          </a>
        </Box>
      </Box>
    </Box>
  )
}

export default Bio
