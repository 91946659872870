import * as React from "react"
import { Link as GatsbyLink, graphql } from "gatsby"
import { Box, Heading, Link } from "theme-ui"
import { jsx } from "theme-ui"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <Bio />
        <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Home" />

      <Box sx={{ mb: [4, 5] }}>
        <Bio intro="Thanks for stopping by!" />
      </Box>

      <Box sx={{ display: ["block", "flex"], flexDirection: "row" }}>
        <Box sx={{ minWidth: ["100%", "180px"], mb: [3, 0] }}>
          <Heading
            sx={{
              fontSize: 18,
              color: "gray",
            }}
          >
            📖 Blog Posts
          </Heading>
        </Box>
        <Box sx={{ variant: "styles.postListing" }}>
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <Box sx={{ variant: "styles.postListing.post" }}>
                <article itemScope itemType="http://schema.org/Article">
                  <header>
                    <Heading as="h2" sx={{ mb: 1 }}>
                      <Link
                        as={GatsbyLink}
                        to={post.fields.slug}
                        itemProp="url"
                        variant="links.simple"
                      >
                        {title}
                      </Link>
                    </Heading>
                    <Box sx={{ color: "gray", fontSize: 14 }}>
                      {post.frontmatter.date}
                    </Box>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.description || post.excerpt,
                      }}
                      itemProp="description"
                    />
                  </section>
                </article>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
